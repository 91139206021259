var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('AdvanceTable',{ref:"advanceTable",attrs:{"url":"erp/roles","height":_vm.window.height - 310,"columns":_vm.columns},on:{"edit":_vm.openModalForEdit},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info"},on:{"click":_vm.openModalForCreate}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("Role")))])],1)]},proxy:true}])})],1),_c('b-modal',{attrs:{"id":"user-modal","centered":"","title":_vm.$t(_vm.title),"size":"lg","hide-footer":""},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"rules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":""}},[_c('b-input',{attrs:{"state":!errors.length ? null : false,"placeholder":"Name"},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Description'),"label-for":""}},[_c('b-input',{attrs:{"state":!errors.length ? null : false,"placeholder":"Description"},model:{value:(_vm.info.description),callback:function ($$v) {_vm.$set(_vm.info, "description", $$v)},expression:"info.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Permissions'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Permissions'),"label-for":""}},[_vm._l((_vm.permissionsList),function(v,i){return _c('span',{key:v.id},[_c('b-form-checkbox',{staticClass:"my-1",attrs:{"value":v.id},model:{value:(_vm.permissionIds),callback:function ($$v) {_vm.permissionIds=$$v},expression:"permissionIds"}},[_vm._v(_vm._s(v.name))])],1)}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],2)}}],null,true)})],1)],1),_c('div',[_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{staticClass:"btn mr-2",attrs:{"variant":"info"},on:{"click":function($event){_vm.isShow=false;_vm.$refs.advanceTable.loadList()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('b-button',{staticClass:"btn",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }