<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="erp/roles" :height="window.height - 310" :columns="columns"  @edit="openModalForEdit">
            <template #button>
                <b-button variant="info" class="mr-1" @click="openModalForCreate">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">{{ $t("Role") }}</span>
                </b-button>
            </template>
        </AdvanceTable>
    </section>
    <b-modal
      id="user-modal"
      centered
      :title="$t(title)"
      v-model="isShow"
      size="lg"
      hide-footer
    >
      <b-card no-body>
            <b-card-body>
                <validation-observer ref="rules" v-slot="{ invalid }">
                    <b-row>
                        <b-col lg="12">
                            <validation-provider :name="$t('Name')" rules="required">
                              <b-form-group
                                :label="$t('Name')"
                                label-for=""
                                slot-scope="{ valid, errors }"
                              >
                                <b-input
                                  v-model="info.name"
                                  :state="!errors.length ? null : false"
                                  placeholder="Name"/>

                                <b-form-invalid-feedback>
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                          <validation-provider :name="$t('Description')">
                            <b-form-group
                              :label="$t('Description')"
                              label-for=""
                              slot-scope="{ valid, errors }"
                            >
                              <b-input
                                v-model="info.description"
                                :state="!errors.length ? null : false"
                                placeholder="Description"/>

                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>

                            <validation-provider :name="$t('Permissions')" rules="required">
                                <b-form-group
                                :label="$t('Permissions')"
                                label-for=""
                                slot-scope="{ valid, errors }"
                                >
                                    <span v-for="(v, i) in permissionsList" :key="v.id">
                                      <b-form-checkbox v-model="permissionIds" :value="v.id" class="my-1">{{ v.name }}</b-form-checkbox>
                                    </span>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                <div>
                    <div class="w-100 d-flex justify-content-end">
                        <b-button
                            variant="info"
                            class="btn mr-2"
                            @click="isShow=false;$refs.advanceTable.loadList()"
                        >
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button
                            variant="primary"
                            class="btn"
                            @click="save"
                            :disabled="invalid"
                        >
                            {{ $t('Save') }}
                        </b-button>
                    </div>
                </div>
                </validation-observer>
            </b-card-body>
        </b-card>
    </b-modal>
</div>
</template>
<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@configs/apis";
import vSelect from "vue-select";
import { formatWithCurrentTimezone } from "./utils/time";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";

export default {
    mixins: [Prompt],
    components:{vSelect,AdvanceTable},
    data(){
        return{
            window: {
                width: 0,
                height: 0
            },
            title:'Add Role',
            isShow:false,
            info:{},
            columns:[
              { key: 'name', modelName:'name', label: 'Name', filtertype: "input", sortable: false},
              { key: 'description', modelName:'description', label: 'Description', filtertype: "input", sortable: false},
            ],
            permissionIds: [],
            sortBy: "",
            sortDesc: true,
            rolesList:[],
            permissionsList:[],
            pipelineList: [],
            nativerolesList: [],
            currencyList:[],
            languageList:[],
            email:'',
            mode: 'CREATE',
        }
    },
    mounted(){
        this.getRoles()
        this.getPermissions()
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods:{
      formatWithCurrentTimezone,
        getFormat(t) {
            let date = new Date(+new Date(t) + 8 * 3600 * 1000)
            let seperator = "-"
            let year = date.getFullYear();
            let month = date.getMonth()+1;
            let strDate = date.getDate();
            let hours = date.getHours();
            let mm = date.getMinutes();
            let s = date.getSeconds();
            if(month >= 1 && month <= 9){
                month = "0" + month
            }
            if(strDate >= 0 && strDate <= 9){
                strDate = "0" + strDate
            }
          return year + seperator + month + seperator + strDate + ' ' + hours + ':' + mm + ':' + s
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        clearData(){
            this.email = ''
            this.role_id= ''
        },
        search(val){
            this.$refs.advanceTable.loadList({'roleId':val});
        },
        openModalForCreate(){
            Object.keys(this.info).forEach(key => (this.info[key] = ''));
            this.$refs.advanceTable.loadList()
            this.isShow = true
            this.mode = 'CREATE';
            this.title = 'Add Role'
            this.permissionIds = []
        },
        openModalForEdit(item){
            this.title = 'Update Role - ' + item.name
            this.isShow = true
            this.mode = 'EDIT';
            this.info = item
            this.username = item.name
            this.permissionIds = item.permissions.map(p => p.id)

          console.log(item)
        },
        async save(){
            if (this.mode === 'CREATE') {
                try {
                    const response = await axios.post(
                        apis.erpPostRole,
                      {...this.info, permissionIds: this.permissionIds}
                    )
                    if (response.error) {
                        this.promptError(response.error);
                        return false;
                    }
                    this.isShow = false
                    this.promptInfo('Add Role Success');

                    await this.$refs.advanceTable.loadList();
                }catch (e) {
                  this.promptError(e);
                }
            } else if (this.mode === 'EDIT') {
                try {
                    const response = await axios.put(
                        apis.erpPutRole+'/'+this.info.id,
                      {...this.info, permissionIds: this.permissionIds}
                    )
                    if (response.error) {
                      this.promptError(response.error);
                        return false;
                    }
                    this.isShow = false
                    this.promptInfo('Update Role Success');
                    await this.$refs.advanceTable.loadList();
                } catch (e) {
                  this.promptError(e);
                }
            }
        },
        async getRoles(){
          const response = await axios.get(
              apis.erpGetRoles,
              {}
          )
          this.rolesList = response.data.data.data
        },
        async getPermissions() {
          const response = await axios.get(
            apis.erpGetPermissions,
            {}
          )
          this.permissionsList = response.data.data.data
        }
    }
}
</script>
